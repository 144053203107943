@import "~@/styles/variables/variables.scss";




























































































































































































































































































































































@import '../runLayout.scss';
.contrast-list {
	position: fixed;
	top: 80px;
	right: 20px;
}
