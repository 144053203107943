@import "~@/styles/variables/variables.scss";


































































































































































































// 进入动画
.dialog-fade-enter-active {
	animation: dialog-fade-in 0.4s;
}
// 离开动画
.dialog-fade-leave-active {
	animation: dialog-fade-out 0.4s;
}

@keyframes dialog-fade-in {
	0% {
		transform: translate3d(0, -20px, 0);
		opacity: 0;
	}
	100% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes dialog-fade-out {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	100% {
		transform: translate3d(0, -20px, 0);
		opacity: 0;
	}
}
